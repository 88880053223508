import React, { useState, useEffect } from "react";
import { Skeleton, Button, Col, Row } from "antd";
import { CustomCard } from "Components";
import { VAATable } from "Components/vaa-table";
import { KpiGroup } from "Components/Markup";
import { getRecentSlaughterData } from "util/Adapter";
import styles from "./styles.module.scss";

export const Slacht = ({ participation }) => {
  const [loading, setLoading] = useState(true);
  const [slaughterData, setSlaughterData] = useState([]);

  useEffect(() => {
    if (participation && !participation.participationCreated && participation.participation === null) {
      getRecentSlaughterData().then((resp) => {
        setSlaughterData(resp);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [participation]);

  return (
    <Skeleton loading={loading} active>
      <CustomCard bordered={false} bodyStyle={{ padding: 0 }}>
        <KpiGroup>
          <VAATable pagination={false} dataSource={slaughterData} columns={columns} rowClassName={(record) => (record.isNew ? styles.new : null)} />
        </KpiGroup>
      </CustomCard>
    </Skeleton>
  );
};

const columns = [
  {
    dataIndex: "worknumber",
    title: "Werknummer",
  },
  {
    dataIndex: "weight",
    title: "Gewicht",
  },
  {
    dataIndex: "seurop",
    title: "SEUROP",
  },
  {
    dataIndex: "fat",
    title: "Vetbedekking",
  },
  {
    dataIndex: "kds",
    title: "Aantal KDS registraties",
  },
];
