import React from "react";
import { Card } from "antd";
import styles from "./grid.module.scss";

export const CustomCard = ({ style, children, className, bodyStyle = { padding: 50 }, shadow = true, margin = true }) => {
  const cardStyles = [styles.card];

  if (margin) cardStyles.push(styles.margin);
  if (!shadow) cardStyles.push(styles["no-shadow"]);
  if (className && className.length) className.map(cn => cardStyles.push(styles[cn]));

  return (
    <Card style={style} bordered={false} bodyStyle={bodyStyle} className={cardStyles.join(" ")}>
      {children}
    </Card>
  );
};
