import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Skeleton } from "antd";
import { Kpi, CustomCard, KpiGroup, NoData } from "Components";

import { getBizViewGroup, getPeriods, getLatest } from "util/Adapter";
import { ContextStore } from "Components/Store";
import { PeriodNote } from "Components/PeriodNote";
import { Benchmark } from "./benchmark";

import { ReactComponent as Cow } from "./Icons/cow.svg";
import { ReactComponent as Grass } from "./Icons/grass_corn.svg";
import { ReactComponent as Milk } from "./Icons/milk.svg";
import { ReactComponent as ArrowIn } from "./Icons/arrow_in.svg";
import { ReactComponent as ArrowOut } from "./Icons/arrow_out.svg";

import styles from "./eiwit.module.scss";

export const Eiwit = ({ period }) => {
  const { dispatch } = useContext(ContextStore);
  const [latestPeriod, setLatestPeriod] = useState();
  const [loading, setLoading] = useState(true);

  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setLoading(true);
    setHasData(false);
    if (period) {
      // Misschien nog veranderen naar ocb_voer?
      getPeriods("ocb_eiwit", "j").then((ps) => {
        const periodFound = ps.find((p) => p.value === period);
        if (periodFound) {
          getBizViewGroup("ocb_eiwit", undefined, period)
            .then((res) => {
              dispatch({ type: "setKPI", data: res.data, group: "ocb_eiwit" });
              setHasData(true);
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("ocb_eiwit", "j")
        .then((res) => {
          dispatch({ type: "setKPI", data: res.data, group: "ocb_eiwit" });
          setLatestPeriod(res.data.period.value);
          setHasData(true);
        })
        .finally(() => setLoading(false));
    }
  }, [period]);

  return (
    <Skeleton loading={loading} active>
      {hasData ? (
        <CustomCard bordered={false} bodyStyle={{ padding: 30 }}>
          <KpiGroup>
            <Row type="flex" align="middle">
              <Col md={12} span={24}>
                Eiwit
              </Col>
              <Col md={12} span={24} align="right">
                <Benchmark period={latestPeriod || period} />
              </Col>
            </Row>
            <Row type="flex" gutter={20} align="bottom">
              <Col md={5} span={24}>
                <Kpi group="ocb_eiwit" kpiKey="re_gev_koe" type="small" />
                <Kpi group="ocb_eiwit" kpiKey="rantsoen_re" type="small" />
              </Col>
              <Col md={3} align="center" className={styles.iconCol}>
                <ArrowIn />
                <Grass />
              </Col>
              <Col md={8} span={24} align="center">
                <Cow style={{ marginBottom: 20 }} />
                <Kpi group="ocb_eiwit" kpiKey="stik_ben_vee" type="large" align="center" format="percentage" />
              </Col>
              <Col md={3} align="center" className={styles.iconCol}>
                <ArrowOut />
                <Milk />
              </Col>
              <Col md={5} span={24}>
                <Kpi group="ocb_eiwit" kpiKey="re_gel_koe" type="small" align="right" />
              </Col>
            </Row>
          </KpiGroup>
          <PeriodNote value={period} group="ocb_eiwit" />
        </CustomCard>
      ) : (
        <NoData name="Eiwit" />
      )}
    </Skeleton>
  );
};
