import React from "react";
import { Kpi } from "Components";
import { Col, Skeleton } from "antd";
import background from "./background.png";
import styles from "./general.module.scss";

export const EiwitEigenLand = ({ loading }) => {
  return (
    <div className={styles.eiwit}>
      <div className={styles.kpi}>
        <Skeleton loading={loading} active>
          <Kpi group="ocb_algemeen" kpiKey="pceigen_n" color="white" type="large" />
        </Skeleton>
      </div>
    </div>
  );
};
