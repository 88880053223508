import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { nivoScheme } from "util/helpers";

export const RatioDiagram = ({ data }) => {
  return (
    <div style={{ height: 400 }}>
      <ResponsivePie
        data={data}
        innerRadius={0.5}
        padAngle={0}
        margin={{ right: 150 }}
        colors={nivoScheme.colors}
        theme={nivoScheme.theme}
        slicesLabelsTextColor="#fff"
        enableRadialLabels={false}
        enableSlicesLabels={false}
        sortByValue
        isInteractive={false}
        legends={[
          {
            anchor: "right",
            direction: "column",
            itemWidth: 100,
            itemHeight: 26,
            translateX: 120,
            itemDirection: "left-to-right",
            symbolSize: 10,
            symbolShape: "circle",
          },
        ]}
      />
    </div>
  );
};
