import React, { useContext } from "react";
import { Button, Row } from "antd";
import { ContextStore } from "Components";
import logo from "./opticow-logo.svg";
import styles from "./navigation.module.scss";

export const Navigation = () => {
  const { state } = useContext(ContextStore);

  return (
    <Row type="flex" align="middle" justify="space-between" className={styles.navigation}>
      <img src={logo} alt="OPTIcow logo" height={60} />
      <Button type="primary" onClick={state.keycloak.logout}>
        Uitloggen
      </Button>
    </Row>
  );
};
