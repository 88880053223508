import React, { useState, useEffect, useContext } from "react";
import { CustomButton, Plot, CustomModal, Kpi, Accent, KpiGroup } from "Components";

import { Col, Row } from "antd";
import { getBenchmark, getBenchmarkAverages } from "util/Adapter";
import { ContextStore } from "Components/Store";

export const Benchmark = ({ period }) => {
  const { state, dispatch } = useContext(ContextStore);

  const [visible, setVisible] = useState(false);
  const [axis, setAxis] = useState({ x: "stik_ben_vee", y: "rantsoen_re" });
  const [benchmarkData, setBenchmarkData] = useState([]);

  useEffect(() => {
    if (period) {
      getBenchmark({
        schema: "OCBASIS",
        selectionType: "mlkkoe",
        group: "ocb_eiwit",
        periodValue: period,
      }).then((r) => setBenchmarkData(r));

      getBenchmarkAverages({
        schema: "OCBASIS",
        selectionType: "mlkkoe",
        group: "ocb_eiwit",
        periodValue: period,
      }).then((r) => dispatch({ type: "setKPI", data: r, group: "benchmark_ocb_eiwit" }));
    }
  }, [period]);

  const xValue = state.kpi.ocb_eiwit.kpiValues.find((x) => x.key === axis.x);
  const yValue = state.kpi.ocb_eiwit.kpiValues.find((x) => x.key === axis.y);

  const myBenchmarkData = state.kpi.ocb_eiwit && {
    x: xValue ? parseFloat(xValue.value * 100) : 0,
    y: yValue ? parseFloat(yValue.value) : 0,
  };

  return (
    <>
      <CustomButton gradient icon="eye" onClick={() => setVisible(!visible)}>
        Benchmark
      </CustomButton>
      <CustomModal visible={visible} setVisible={setVisible} footer={false}>
        <KpiGroup>
          <Row gutter={60} style={{ margin: 0 }}>
            <Col span={24}>
              <Accent>Benchmark Eiwit</Accent>
            </Col>
            <Col span={12} style={{ paddingBottom: 30, paddingTop: 30 }}>
              Mijn bedrijf
              <Kpi group="ocb_eiwit" kpiKey="stik_ben_vee" type="large" format="percentage" />
              <Kpi group="ocb_eiwit" kpiKey="rantsoen_re" />
              <Kpi group="ocb_eiwit" kpiKey="re_gev_koe" />
              <Kpi group="ocb_eiwit" kpiKey="re_gel_koe" />
            </Col>
            <Col span={12} style={{ backgroundColor: "#B468CB", paddingBottom: 30, paddingTop: 30 }}>
              <span style={{ color: "#fff" }}>Benchmark</span>
              <Kpi group="benchmark_ocb_eiwit" kpiKey="stik_ben_vee" type="large" color="white" format="percentage" />
              <Kpi group="benchmark_ocb_eiwit" kpiKey="rantsoen_re" color="white" />
              <Kpi group="benchmark_ocb_eiwit" kpiKey="re_gev_koe" color="white" />
              <Kpi group="benchmark_ocb_eiwit" kpiKey="re_gel_koe" color="white" />
            </Col>
            <Col span={24}>
              <Plot myData={myBenchmarkData} data={benchmarkData} x={axis.x} y={axis.y} calc={{ x: 100, y: 1 }} />
            </Col>
            <Col span={24} style={{ backgroundColor: "#EBF0F2", paddingTop: 30, paddingBottom: 30 }}>
              <Accent type="div">Analyse</Accent>
              <p>
                Het getal Eiwitbenutting veestapel geeft weer hoe efficiënt een koe omgaat met het eiwit uit het rantsoen. Het gaat daarbij om de
                omzetting van voereiwit naar melkeiwit. Dit getal is sterk afhankelijk van het RE/kg ds. Een lager RE/kg ds geeft in verhouding een
                hogere Eiwitbenutting van de veestapel. Eiwit benutting is van meerdere factoren afhankelijk. Schakel met je OPTIcow-adviseur om te
                kijken waar je kunt optimaliseren.
              </p>
            </Col>
          </Row>
        </KpiGroup>
      </CustomModal>
    </>
  );
};
