import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Skeleton } from "antd";
import { Kpi, CustomCard, KpiGroup, ContextStore, NoData } from "Components";

import { getBizViewGroup, getLatest, getPeriods } from "util/Adapter";

import { PeriodNote } from "Components/PeriodNote";
import { Benchmark } from "./benchmark";

export const Emissies = ({ period }) => {
  const {
    state: {
      kpi: { ocb_emissies },
    },
    dispatch,
  } = useContext(ContextStore);

  const [loading, setLoading] = useState(true);
  const [latestPeriod, setLatestPeriod] = useState();
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setLoading(true);
    setHasData(false);
    if (period) {
      // Misschien nog veranderen naar ocb_voer?
      getPeriods("ocb_eiwit", "j").then((ps) => {
        const periodFound = ps.find((p) => p.value === period);
        if (periodFound) {
          getBizViewGroup("ocb_emissies", undefined, period)
            .then((res) => {
              dispatch({ type: "setKPI", data: res.data, group: "ocb_emissies" });
              setHasData(true);
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("ocb_emissies", "j")
        .then((res) => {
          dispatch({ type: "setKPI", data: res.data, group: "ocb_emissies" });
          setLatestPeriod(res.data.period.value);
          setHasData(true);
        })
        .finally(() => setLoading(false));
    }
  }, [period]);

  return (
    <Skeleton loading={loading} active>
      {hasData ? (
        <CustomCard bordered={false} bodyStyle={{ padding: 30 }}>
          <KpiGroup>
            <Row type="flex" align="middle">
              <Col span={12}>Emissie</Col>
              <Col span={12} align="right">
                <Benchmark period={latestPeriod || period} />
              </Col>
            </Row>

            {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_melkprod_bed_waarde0") ? (
              <Kpi group="ocb_emissies" kpiKey="co2_melkprod_bed_waarde0" type="large" />
            ) : (
              <Kpi group="ocb_emissies" kpiKey="emco2_tot_mlk_sp" type="large" />
            )}

            <Row type="flex" gutter={20}>
              <Col span={24} md={8}>
                <Kpi group="ocb_emissies" kpiKey="over_bod_gras1" />
              </Col>
              <Col span={24} md={8}>
                <Kpi group="ocb_emissies" kpiKey="over_bod_mais1" />
              </Col>
              {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "over_bod_akker1") && (
                <Col span={24} md={8}>
                  <Kpi group="ocb_emissies" kpiKey="over_bod_akker1" />
                </Col>
              )}
              <Col span={24} md={8}>
                <Kpi group="ocb_emissies" kpiKey="dzh_nh3_bedrgve" />
              </Col>
              {/* <Col span={24} md={8}>
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_mestopsl_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_mestopsl_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_mo" />
                )}
              </Col>
              <Col span={24} md={8}>
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_aanvoer_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_aanvoer_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_cf_aank" />
                )}
              </Col>
              <Col span={24} md={8}>
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_voerprod_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_voerprod_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_bnr" />
                )}
              </Col>
              <Col span={24} md={8}>
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_pensferm_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_pensferm_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_pv" />
                )}
              </Col> */}
            </Row>
          </KpiGroup>
          <PeriodNote value={period} group="ocb_emissies" />
        </CustomCard>
      ) : (
        <NoData name="Emissies" />
      )}
    </Skeleton>
  );
};
