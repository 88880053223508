import React, { useContext } from "react";
import { ContextStore } from "Components/Store";

export const PeriodNote = ({ value, group }) => {
  const { state } = useContext(ContextStore);

  let period;

  if (value) {
    period = value;
  } else if (group && state.kpi[group] && state.kpi[group].period) {
    period = `${state.kpi[group].period.value}`;
    if (state.kpi[group].period.unit === "M01") {
      period += ` - ${state.kpi[group].period.number}`;
    }
  }

  return (
    <div style={{ textAlign: "right", opacity: 0.6 }}>
      <small>
        Data van <strong style={{ fontWeight: 500 }}>{period}</strong>
      </small>
    </div>
  );
};
