import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { nivoScheme } from "util/helpers";
import { Skeleton } from "antd";

export const Line = ({ keys, data, axisLegend = {} }) => {
  const [chartLoading, setChartLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setChartLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ height: 400 }}>
      <Skeleton active loading={chartLoading} height={400}>
        <ResponsiveLine
          height={400}
          data={data}
          margin={{ top: 10, left: 60, bottom: 100, right: 50 }}
          colors={nivoScheme.colors}
          enableLabel={false}
          theme={nivoScheme.theme}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: axisLegend.left,
            legendOffset: -50,
            legendPosition: "middle",
          }}
          axisBottom={{
            tickRotation: -30,
            legend: axisLegend.bottom,
            legendOffset: -40,
            legendPosition: "middle",
          }}
          legends={[
            {
              dataFrom: "keys",
              reverse: true,
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateY: 80,
              itemsSpacing: 2,
              itemWidth: 180,
              itemHeight: 20,
              itemDirection: "left-to-right",
              symbolSize: 10,
              symbolShape: "square",
            },
          ]}
        />
      </Skeleton>
    </div>
  );
};
