import React from "react";
import { Col, Row } from "antd";
import { Accent, CustomCard, KpiGroup } from "Components";
import { useTranslation } from "react-i18next";
import background from "./background.png";

export const General = ({ style }) => {
  const { t } = useTranslation();

  return (
    <Row type="flex" gutter={20} style={{ margin: "0 0 20px" }}>
      <Col span={24} md={14} style={{ paddingRight: 0 }}>
        <CustomCard bordered={false} style={style} margin={false}>
          <Accent type="h1">Welkom</Accent>
          <p>{t("text.summary.first")}</p>
          <p>{t("text.summary.second")}</p>
          <Accent variant="primary" weight="bold">
            {t("title.reference_company")}
          </Accent>
          <p>{t("text.reference_company")}</p>
        </CustomCard>
      </Col>
      <Col span={24} md={10}>
        <div
          style={{
            height: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${background})`,
          }}
        />
      </Col>
    </Row>
  );
};
