import React, { useState, useEffect, useContext } from "react";
import { Skeleton, Row, Col, Select } from "antd";
import { CustomCard, KpiGroup, Kpi, PeriodNote, NoData } from "Components";
import { getRollingGroup, getPeriods, getLatest } from "util/Adapter";
import { ContextStore } from "Components/Store";
import { Benchmark } from "./benchmark";

export const Tussenkalf = ({ periodYear, periodMonth }) => {
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const { dispatch } = useContext(ContextStore);
  const [latestPeriod, setLatestPeriod] = useState();

  useEffect(() => {
    setLoading(true);
    setHasData(false);
    if (periodYear && periodMonth) {
      getPeriods("OCB_TUSSENKALFTD", "m01").then((ps) => {
        const periodFound = ps.find((p) => p.value === periodYear && p.number === +periodMonth);
        if (periodFound) {
          getRollingGroup({ group: "OCB_TUSSENKALFTD", periodValue: periodYear, periodNumber: periodMonth, numberOfPeriods: 12 })
            .then((res) => dispatch({ type: "setKPI", data: res.data, group: "ocb_tussenkalftd" }))
            .then(() => {
              setLoading(false);
              setHasData(true);
            });
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("OCB_TUSSENKALFTD", "m01")
        .then((res) => {
          dispatch({ type: "setKPI", data: res.data, group: "ocb_tussenkalftd" });
          setLatestPeriod(res.data.period);
        })
        .then(() => {
          setLoading(false);
          setHasData(true);
        });
    }
  }, [periodYear, periodMonth]);

  return (
    <Skeleton loading={loading} active>
      {hasData ? (
        <CustomCard bordered={false} bodyStyle={{ padiding: 30 }}>
          <KpiGroup>
            <Row type="flex" align="middle">
              <Col span={12}>Tussenkalftijd</Col>

              <Col span={12} align="right">
                <Benchmark
                  periodYear={periodYear || (latestPeriod && latestPeriod.value)}
                  periodMonth={periodMonth || (latestPeriod && latestPeriod.number)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Kpi group="ocb_tussenkalftd" kpiKey="rv_i108" type="large" align="center" />
              </Col>
            </Row>
          </KpiGroup>
          <PeriodNote value={periodYear && periodMonth ? `${periodYear}-${periodMonth}` : undefined} group="ocb_tussenkalftd" />
        </CustomCard>
      ) : (
        <NoData name="DierDagDosering totaal" />
      )}
    </Skeleton>
  );
};
