import React, { useState, useEffect, useContext } from "react";
import { Skeleton, Row, Col } from "antd";
import { CustomCard, KpiGroup, Kpi, NoData } from "Components";
import { useTranslation } from "react-i18next";
import { getLatest, getPeriods, getRollingGroup } from "util/Adapter";
import { ContextStore } from "Components/Store";
import { PeriodNote } from "Components/PeriodNote";
import { RatioDiagram } from "./RatioDiagram";
import { Benchmark } from "./benchmark";

export const Dierdagdosering = ({ periodYear, periodMonth }) => {
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [latestPeriod, setLatestPeriod] = useState();
  const { t } = useTranslation();

  const {
    state: {
      kpi: { ocb_ddd },
    },
    dispatch,
  } = useContext(ContextStore);

  useEffect(() => {
    setLoading(true);
    setHasData(false);
    if (periodYear && periodMonth) {
      getPeriods("ocb_ddd", "m01").then((ps) => {
        const periodFound = ps.find((p) => p.value === periodYear && p.number === +periodMonth);
        if (periodFound) {
          getRollingGroup({ group: "ocb_ddd", periodUnit: "m01", periodValue: periodYear, periodNumber: periodMonth, numberOfPeriods: 1 })
            .then((res) => dispatch({ type: "setKPI", data: res.data, group: "ocb_ddd" }))
            .then(() => {
              setLoading(false);
              setHasData(true);
            });
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("ocb_ddd", "m01")
        .then((r) => {
          dispatch({ type: "setKPI", data: r.data, group: "ocb_ddd" });
          setLatestPeriod(r.data.period);
        })
        .then(() => {
          setLoading(false);
          setHasData(true);
        });
    }
  }, [periodYear, periodMonth]);

  const data =
    ocb_ddd &&
    ocb_ddd.kpiValues
      .filter((v) => v.key !== "mr_over_0")
      .map(({ key, value }) => {
        return {
          id: t(`kpi.${key}.label`),
          label: key,
          value: parseFloat(value),
        };
      });

  return (
    <Skeleton loading={loading} active>
      {hasData && data ? (
        <CustomCard bordered={false} bodyStyle={{ padiding: 30 }} className={["split"]}>
          <KpiGroup>
            <Row type="flex" align="middle">
              <Col span={12}>DierDagDosering totaal</Col>
              <Col span={12} align="right">
                <Benchmark periodYear={periodYear} periodMonth={periodMonth} />
              </Col>
            </Row>
            <Row>
              <Kpi group="ocb_ddd" kpiKey="mr_over_0" type="large" align="center" />
            </Row>
          </KpiGroup>
          <RatioDiagram data={data} />
          <PeriodNote value={periodYear && periodMonth ? `${periodYear}-${periodMonth}` : undefined} group="ocb_ddd" />
        </CustomCard>
      ) : (
        <NoData name="DierDagDosering totaal" />
      )}
    </Skeleton>
  );
};
