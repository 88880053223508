import * as React from "react";
import produce from "immer";

const ContextStore = React.createContext();

const initialState = {
  language: "nl",
  keycloak: false,
  keycloakEnabled: true,
  authenticated: false,
  kpi: {},
  participation: false,
  pageActions: undefined,
};

const reducer = (state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "login":
        draft.keycloak = action.keycloak;
        draft.authenticated = action.authenticated;
        draft.user = {
          firstName: action.keycloak.idTokenParsed.given_name,
          lastName: action.keycloak.idTokenParsed.family_name,
          name: action.keycloak.idTokenParsed.name,
          username: action.keycloak.idTokenParsed.preferred_username,
          id: action.keycloak.idTokenParsed.sub,
        };
        break;
      case "setKPI": {
        draft.kpi[action.group] = action.data;
        break;
      }
      case "setParticipationGranted": {
        draft.participation.granted = action.granted;
        break;
      }
      case "disableKC": {
        draft.keycloakEnabled = false;
        break;
      }
      case "setPageActions": {
        draft.pageActions = action.pageActions;
        break;
      }
      default:
        draft[action.group] = action.data;
    }
  });
};

const ContextStoreProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <ContextStore.Provider value={value}>{children}</ContextStore.Provider>;
};

const ContextStoreConsumer = ContextStore.Consumer;

export { ContextStore, ContextStoreProvider, ContextStoreConsumer };
