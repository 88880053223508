import moment from "moment";
import { bizViewAxios, bizViewMock, joindataAxios, klwAxios, opticowAxios } from "./axios";

export const getRelation = () => bizViewAxios.get("/api/relation").then((r) => r.data);

export const getBizViewGroup = (group, unit = "j", value = "2018") =>
  bizViewAxios.get(`/api/kpivalues/group/${group}`, {
    params: {
      periodUnit: unit,
      periodValue: value,
    },
  });
export const getLatest = (group, unit = "j", minimalStatus = "0C") =>
  bizViewAxios.get(`/api/kpivalues/group/${group}/latest`, {
    params: {
      periodUnit: unit,
      minimalStatus,
    },
  });

const periodDate = moment().subtract(1, "months");

export const getRollingGroup = ({
  group,
  periodUnit = "m01",
  periodValue = periodDate.year(),
  periodNumber = periodDate.format("MM"),
  numberOfPeriods = 12,
}) =>
  bizViewAxios.get(`/api/kpivalues/rolling/${group}`, {
    params: {
      periodUnit,
      periodValue,
      periodNumber,
      numberOfPeriods,
    },
  });
export const getBiewViewKpi = () => bizViewAxios.get("/api/kpivalues/keys");
export const getParticipation = () =>
  joindataAxios.get("/api/Participation/check", {
    params: {
      purposeName: "OCBASIS",
    },
  });

export const getKlwData = (dataset, period) => klwAxios.get(`/api/fetchdata/${dataset}/${period}`);
export const startCalculation = (period) =>
  bizViewAxios.get(`/api/calculation/trigger?scheme=OCBASIS&management=OCBUI&periodUnit=J&periodValue=${period}`);

export const getBenchmark = ({ schema, selectionType, group, mergeOtherCompanies = true, periodUnit = "j", periodValue = 2018 }) =>
  bizViewAxios
    .get("/api/Benchmarks", {
      params: {
        databaseSchema: schema,
        selectionType,
        group,
        mergeOtherCompanies,
        periodUnit,
        periodValue,
      },
    })
    .then((r) => r.data);

export const getBenchmarkAverages = ({
  schema = "OCBASIS",
  selectionType = "mlkkoe",
  group,
  mergeOtherCompanies = true,
  periodUnit = "j",
  periodValue = 2018,
}) =>
  bizViewAxios
    .get("/api/Benchmarks/averages", {
      params: {
        databaseSchema: schema,
        selectionType,
        group,
        mergeOtherCompanies,
        periodUnit,
        periodValue,
      },
    })
    .then((r) => {
      return {
        ...r.data,
        kpiValues: r.data.kpiValues.map((x) => {
          return {
            key: x.kpi,
            value: x.kpiValue,
          };
        }),
      };
    });

export const getRollingBenchmark = ({
  schema = "OCBASIS",
  selectionType = "mlkkoe",
  group,
  periodUnit = "m01",
  periodValue = periodDate.year(),
  periodNumber = periodDate.format("MM"),
  numberOfPeriods = 12,
}) =>
  bizViewAxios
    .get(`/api/Benchmarks/rolling/${group}`, {
      params: {
        databaseSchema: schema,
        selectionType,
        group,
        periodUnit,
        periodValue,
        periodNumber,
        numberOfPeriods,
      },
    })
    .then((r) => r.data);

export const getSlachtDataParticipation = () =>
  joindataAxios
    .get("/api/Participation/check", {
      params: {
        purposeName: "OCSLACHT",
      },
    })
    .then((r) => r.data);
export const getSlachtDataAmountSinceLastLogin = () => opticowAxios.get(`/api/SlachtData/amountSinceLogin`).then((r) => r.data);
export const getRecentSlaughterData = () =>
  opticowAxios
    .get(`/api/SlachtData/RecentSlaughterData`, {
      params: {
        maxAmount: 10,
      },
    })
    .then((r) => r.data);

export const getPeriods = (group, periodUnit) =>
  bizViewAxios
    .get(`/api/Periods`, {
      params: {
        group,
        periodUnit,
      },
    })
    .then((r) => r.data);
