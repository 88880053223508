import React, { useState, useContext, useEffect, Suspense } from "react";
import Keycloak from "keycloak-js";
import { BrowserRouter } from "react-router-dom";

import { ContextStore, JoinDataIntegration } from "Components";

import "Assets/_main.scss";
import { Spin } from "antd";
import { getParticipation } from "util/Adapter";
import { setupAxiosWithKeycloak } from "util/axios";
import { Dashboard } from "Pages";

export const App = () => {
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(ContextStore);

  const keycloak = new Keycloak({
    realm: "zlto",
    url: window.AUTH_URL,
    clientId: "opticow-frontend",
  });

  const participationHandler = () => {
    getParticipation()
      .then((r) => dispatch({ type: "setParticipation", group: "participation", data: r.data }))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    keycloak
      .init()
      .success((authenticated) => {
        if (authenticated) {
          setupAxiosWithKeycloak(keycloak).then(() => {
            dispatch({ type: "login", keycloak, authenticated });
            participationHandler();
          });
        } else {
          keycloak.login({ idpHint: "joindata" });
        }
      })
      .error();
  }, []);

  useEffect(() => {});

  const onCloseHandler = () => participationHandler();

  if (loading) return <Spin size="large" />;

  if (state.participation.participationCreated && !state.participation.granted) {
    return (
      <Suspense fallback={<Spin />}>
        <JoinDataIntegration onClose={onCloseHandler} participationId={state.participation.participation.id} visible={state.participation.created} />
      </Suspense>
    );
  }

  return (
    <BrowserRouter>
      <Dashboard />
    </BrowserRouter>
  );
};
