import React, { useEffect, useState } from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { nivoScheme } from "util/helpers";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

export const Plot = ({ myData, data, x, y, calc = { x: 1, y: 1 } }) => {
  const [chartLoading, setChartLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setChartLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const { t } = useTranslation();

  const mappedData = data.map((group) => {
    return {
      id: t([`scatter.${group.selection}`, `scatter.referenties`, group.selection]),
      data: group.data
        .filter((d) => d[x] && d[y])
        .map((d) => {
          return {
            x: d[x] * calc.x,
            y: d[y] * calc.y,
          };
        }),
    };
  });

  const combinedData = [...mappedData.reverse(), { id: "Mijn bedrijf", data: [{ ...myData }] }];

  return (
    <div style={{ height: 400 }}>
      <Skeleton active loading={chartLoading} height={400}>
        <ResponsiveScatterPlot
          data={combinedData}
          margin={{ top: 90, left: 50, bottom: 100, right: 40 }}
          colors={nivoScheme.colors}
          theme={nivoScheme.theme}
          xScale={{ type: "linear", min: "auto", max: "auto" }}
          yScale={{ type: "linear", min: "auto", max: "auto" }}
          isInteractive={false}
          nodeSize={10}
          animate={false}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t(`kpi.${y}.label`),
            legendPosition: "middle",
            legendOffset: -40,
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "row",
              justify: false,
              translateX: 60,
              translateY: -40,
              itemsSpacing: 2,
              itemWidth: 140,
              itemHeight: 20,
              itemDirection: "left-to-right",
              symbolSize: 10,
              symbolShape: "circle",
            },
          ]}
          axisBottom={{
            orient: "bottom",
            legend: t(`kpi.${x}.label`),
            legendPosition: "middle",
            legendOffset: 40,
          }}
        />
        )}
      </Skeleton>
    </div>
  );
};
