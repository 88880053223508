import React from "react";

export const ContactInfo = () => {
  return (
    <>
      <p>
        ZLTO Algemeen: <a href="mailto:info@zlto.nl">info@zlto.nl</a>
        <br />
        <a href="tel:+31732173000">+31 73 217 30 00</a> (algemeen)
        <br />
        <a href="tel:+31 73 217 33 33">+31 73 217 33 33</a> (voor leden)
      </p>
    </>
  );
};
