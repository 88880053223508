import React, { useEffect, useState } from "react";
import { Bar as NivoBar, ResponsiveBar } from "@nivo/bar";
import { nivoScheme } from "util/helpers";
import { Skeleton } from "antd";

export const Bar = ({
  keys,
  data,
  axisLeft = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
  },
  legends = [
    {
      dataFrom: "keys",
      anchor: "right",
      direction: "column",
      justify: false,
      translateX: 200,
      itemsSpacing: 2,
      itemWidth: 180,
      itemHeight: 20,
      itemDirection: "left-to-right",
      symbolSize: 10,
      symbolShape: "circle",
    },
  ],
}) => {
  const [chartLoading, setChartLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setChartLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const bottomLegends = legends.filter((x) => x.anchor === "bottom");

  const marginRight = legends.length > 0 && legends[0].anchor === "right" ? 200 : 10;
  const marginBottom = legends.length > 0 && bottomLegends.length > 0 ? 120 : 40;
  const marginLeft = axisLeft ? 40 : 10;

  return (
    <div style={{ height: 400 }}>
      <Skeleton active loading={chartLoading} height={400}>
        <ResponsiveBar
          height={400}
          data={data}
          indexBy="label"
          keys={keys}
          margin={{ top: 10, left: marginLeft, bottom: marginBottom, right: marginRight }}
          colors={nivoScheme.colors}
          enableLabel={false}
          theme={nivoScheme.theme}
          axisLeft={axisLeft}
          legends={legends}
          isInteractive={false}
          animate={false}
          motionStiffness={90}
          motionDamping={15}
        />
      </Skeleton>
    </div>
  );
};
