import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Skeleton, Select } from "antd";
import { Voer, Eiwit, Emissies, CustomCard, KpiGroup, Kpi, PeriodNote, ContextStore, Accent, NoData } from "Components";
import { EiwitEigenLand } from "Components/Cards/General/EiwitEigenLand";
import { getPeriods, getBizViewGroup, startCalculation, getLatest } from "util/Adapter";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { Option } = Select;

const currentYear = moment().year() - 1;
const years = [currentYear, currentYear - 1, currentYear - 2];

export const Kringloop = () => {
  const { state, dispatch } = useContext(ContextStore);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [period, setPeriod] = useState();
  const [hasKlwData, setHasKlwData] = useState(false);
  const [klwPeriod, setKlwPeriod] = useState();
  const [isCalculating, calculating] = useState(true);

  useEffect(() => {
    calculating(true);
    if (period) {
      startCalculation(period).then(() => calculating(false));
    } else {
      startCalculation(moment().year()).then(() => calculating(false));
    }
  }, [period]);

  useEffect(() => {
    setLoading(true);
    setHasKlwData(false);
    if (period) {
      // KLW data
      getPeriods("ocb_algemeen", "j").then((ps) => {
        const periodFound = ps.find((p) => p.value === period);
        if (periodFound) {
          getBizViewGroup("ocb_algemeen", "J", period)
            .then((res) => {
              dispatch({ type: "setKPI", data: res.data, group: "ocb_algemeen" });
              setHasKlwData(true);
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("ocb_algemeen", "j")
        .then((res) => {
          dispatch({ type: "setKPI", data: res.data, group: "ocb_algemeen" });
          setHasKlwData(true);
        })
        .finally(() => setLoading(false));
    }
  }, [period]);

  // useEffect(() => {
  //   dispatch({
  //     type: "setPageActions",
  //     pageActions: (
  //       <Row>
  //         Kies een periode:
  //         <Select allowClear value={period} onChange={setPeriod} style={{ width: "200px" }}>
  //           {years.map((year) => (
  //             <Option key={year} value={year}>
  //               {year}
  //             </Option>
  //           ))}
  //         </Select>
  //       </Row>
  //     ),
  //   });
  // }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", paddingLeft: "10px", paddingRight: "10px", top: "-66px", right: "0px" }}>
          Kies een periode:
          <Select allowClear value={period} onChange={setPeriod} placeholder="Jaar" style={{ marginLeft: "10px", width: "100px" }}>
            {years.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </div>
        <Skeleton loading={isCalculating}>
          <Row type="flex" gutter={20} style={{ margin: 0 }}>
            {hasKlwData ? (
              <>
                <Col
                  span={16}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CustomCard bodyStyle={{ padding: "24px" }}>
                    <Row type="flex" align="middle">
                      <Col md={12} span={24}>
                        {t("title.general_data")}
                      </Col>
                    </Row>
                    <KpiGroup>
                      <Skeleton active loading={loading}>
                        <Row type="flex" gutter={20}>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="nkoe" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="melk_bedr" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="melk_koe" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="melk_ha" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="eiwit" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="vet" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="melk_ureum" />
                          </Col>
                          <Col md={6} span={24}>
                            <Kpi group="ocb_algemeen" kpiKey="jvper10mk" />
                          </Col>
                        </Row>
                        <PeriodNote value={period} group="ocb_algemeen" />
                      </Skeleton>
                    </KpiGroup>
                  </CustomCard>
                </Col>
                <Col
                  span={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <EiwitEigenLand loading={loading} />
                </Col>
              </>
            ) : (
              <NoData name={t("title.general_data")} />
            )}
          </Row>
          <Row type="flex" gutter={20} style={{ margin: 0 }}>
            <Col
              span={24}
              md={10}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Voer period={period} />
            </Col>
            <Col
              span={24}
              md={14}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Eiwit period={period} />
              <Emissies period={period} />
            </Col>
          </Row>
        </Skeleton>
      </div>
    </>
  );
};
