import React from "react";
import { CustomModal, Accent } from "Components";
import { Row, Col } from "antd";

export const JoinDataIntegration = ({ participationId, onClose }) => {
  return (
    <CustomModal onClose={onClose} visible centered width={700}>
      <Row gutter={60} style={{ margin: 0 }}>
        <Col span={24}>
          <Accent type="h2">Mijn JoinData machtigingen</Accent>

          <p>
            Om de applicatie toegang te geven tot je data verwachten we een machtiging met JoinData.
            Vul hem hieronder aan:
          </p>

          <iframe
            width="100%"
            src={`https://${window.JOINDATA_ENV}.join-data.net/onboarding/?participation=${participationId}&client-id=${window.JOINDATA_CLIENT}`}
            frameBorder="none"
            height="500"
            title="Joindata onboarding"
            id="onboarding"
          />
        </Col>
      </Row>
    </CustomModal>
  );
};
