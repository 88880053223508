import React from "react";
import { Row, Col } from "antd";
import { CustomCard } from "Components/Grid";
import { Accent } from "Components/Markup";

import bg from "./placeholder.jpg";

export const Placeholder = () => {
  return (
    <Row gutter={20} style={{ margin: 0 }}>
      <Col span={24}>
        <CustomCard style={{ textAlign: "center", backgroundImage: `url(${bg})` }}>
          <Accent type="h1">Helaas, je hebt onvoldoende rechten</Accent>
          <p>Wilt u gebruik maken van deze module?</p>
          <p>Neem dan contact op met uw contactpersoon bij ZLTO.</p>
        </CustomCard>
      </Col>
    </Row>
  );
};
