import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Spin, Skeleton, Select } from "antd";
import { Afkalf, Tussenkalf, Dierdagdosering, Placeholder, ContextStore } from "Components";
import { getBizViewGroup, getPeriods } from "util/Adapter";
import moment from "moment";

const currentYear = moment().year();

const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
const months = [
  {
    value: "01",
    name: "Januari",
  },
  {
    value: "02",
    name: "Februari",
  },
  {
    value: "03",
    name: "Maart",
  },
  {
    value: "04",
    name: "April",
  },
  {
    value: "05",
    name: "Mei",
  },
  {
    value: "06",
    name: "Juni",
  },
  {
    value: "07",
    name: "Juli",
  },
  {
    value: "08",
    name: "Augustus",
  },
  {
    value: "09",
    name: "September",
  },
  {
    value: "10",
    name: "Oktober",
  },
  {
    value: "11",
    name: "November",
  },
  {
    value: "12",
    name: "December",
  },
];

export const Veemanagement = () => {
  const { dispatch } = useContext(ContextStore);
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [periodYear, setPeriodYear] = useState();
  const [periodMonth, setPeriodMonth] = useState();
  const [period, setPeriod] = useState({ year: undefined, month: undefined });

  useEffect(() => {
    getBizViewGroup("OCB_VEEMGMT_TOP")
      .then(() => setAuthorized(true))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (periodMonth && periodYear) {
      setPeriod({ year: periodYear, month: periodMonth });
    } else if (!periodMonth && !periodYear) {
      setPeriod({ year: undefined, month: undefined });
    }
  }, [periodMonth, periodYear]);

  if (loading)
    return (
      <Row>
        <Spin />
      </Row>
    );

  if (!authorized) return <Placeholder />;

  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", paddingLeft: "10px", paddingRight: "10px", top: "-66px", right: "0px" }}>
          Kies een periode:
          <Select allowClear value={periodYear} onChange={setPeriodYear} placeholder="Jaar" style={{ marginLeft: "10px", width: "100px" }}>
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
          <Select allowClear value={periodMonth} onChange={setPeriodMonth} placeholder="Maand" style={{ marginLeft: "10px", width: "140px" }}>
            {months.map((month) => (
              <Select.Option key={month.value} value={month.value}>
                {month.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Row type="flex" gutter={20} style={{ margin: 0 }}>
          <Col
            span={24}
            md={14}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Afkalf periodYear={period.year} periodMonth={period.month} />
          </Col>
          <Col
            span={24}
            md={10}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Tussenkalf periodYear={period.year} periodMonth={period.month} />
            <Dierdagdosering periodYear={period.year} periodMonth={period.month} />
          </Col>
        </Row>
      </div>
    </>
  );
};
