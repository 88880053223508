import React from "react";
import { Button } from "antd";
import styles from "./button.module.scss";

export const CustomButton = ({ type, size, shape, gradient, children, icon, onClick, href, target }) => {
  const buttonStyles = [styles.button];
  if (gradient) buttonStyles.push(styles.gradient);

  return (
    <Button size={size} type={type} icon={icon} shape={shape} onClick={onClick} href={href} target={target} className={buttonStyles.join(" ")}>
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  gradient: false,
  shape: "round",
  type: "primary",
};
