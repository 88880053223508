import React from "react";
import { Row, Col } from "antd";
import { Accent, CustomButton } from "Components";
import { useTranslation } from "react-i18next";

import { ContactInfo } from "Components/ContactInfo";
import logo from "./logo.png";
import styles from "./footer.module.scss";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <Row type="flex" gutter={40} style={{ marginLeft: 0, marginRight: 0 }} className={styles.footer}>
      <Col span={24}>
        <img src={logo} alt="ZLTO" />
      </Col>
      <Col md={6} span={24} className={styles.widget}>
        <Accent type="div" variant="primary" weight="bold">
          Contact
        </Accent>
        <ContactInfo />
        <a href="https://www.zlto.nl" target="_blank" rel="noopener noreferrer">
          <CustomButton>Ga naar de website van ZLTO</CustomButton>
        </a>
      </Col>
      <Col md={12} span={24} className={styles.widget}>
        <Accent type="div" variant="primary" weight="bold">
          OPTIcow
        </Accent>
        <p>{t("text.opticow")}</p>

        <a href="https://www.zlto.nl/opticow" target="_blank" rel="noopener noreferrer">
          <CustomButton>Meer over OPTIcow</CustomButton>
        </a>
      </Col>
      <Col md={6} span={24} className={styles.widget}>
        <Accent type="div" variant="primary" weight="bold">
          Adviseur
        </Accent>
        <p>
          Harm de Kleynen
          <br />
          <a href="mailto:harm.de.kleynen@zlto.nl">harm.de.kleynen@zlto.nl</a>
          <br />
          <a href="tel:+31629520292">+31 6 29 52 0292</a>
        </p>
        <p>
          <Accent type="div" variant="primary" weight="bold">
            Privacy & Algemene voorwaarden
          </Accent>
          <a href="/Algemene-voorwaarden-ZLTO-gedep-28-nov-2016.pdf" download target="_blank" rel="noopener noreferrer">
            Algemene voorwaarden
          </a>
          <br />
          <a href="https://www.zlto.nl/paginas/openbaar/algemeen/disclaimer" target="_blank" rel="noopener noreferrer">
            Disclaimer
          </a>
          <br />
          <a href="https://www.zlto.nl/privacyverklaring" target="_blank" rel="noopener noreferrer">
            Privacyverklaring
          </a>
          <br />
          <a href="https://www.zlto.nl/paginas/openbaar/algemeen/cookieverklaring" target="_blank" rel="noopener noreferrer">
            Cookieverklaring
          </a>
        </p>
      </Col>
    </Row>
  );
};
