import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { CustomButton, Kpi, Bar, ContextStore, KpiGroup, Accent, CustomModal, Line } from "Components";
import { getBenchmarkAverages, getBenchmark, getRollingGroup, getRollingBenchmark } from "util/Adapter";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const Benchmark = ({ periodYear, periodMonth }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [tussenkalf, setTussenkalf] = useState();
  const { t } = useTranslation();

  const currentDate = moment();
  const {
    state: {
      kpi: { ocb_tussenkalftd, benchmark_ocb_tussenkalftd },
    },
    dispatch,
  } = useContext(ContextStore);

  useEffect(() => {
    if (periodYear && periodMonth) {
      getRollingBenchmark({
        schema: "OCBASIS",
        selectionType: "mlkkoe",
        group: "ocb_tussenkalftd",
        periodValue: periodYear === currentDate.year() ? periodYear - 1 : periodYear,
        periodNumber: periodYear === currentDate.year() ? 12 : periodMonth,
      })
        .then((r) => dispatch({ type: "setKPI", data: r, group: "benchmark_ocb_tussenkalftd" }))
        .finally(() => setLoading(false));
    }
    if (ocb_tussenkalftd.period) {
      getRollingGroup({
        group: "OCB_TUSSENKALFTD",
        periodValue: ocb_tussenkalftd.period.value,
        periodNumber: ocb_tussenkalftd.period.number,
        numberOfPeriods: 12,
      })
        .then((res) => setTussenkalf(res.data))
        .then(() => setLoading(false));
    }
  }, [periodYear, periodMonth]);

  const prepPeriodValue = (periodValue) => (`${periodValue}`.length === 1 ? `0${periodValue}` : periodValue);

  let myData = tussenkalf
    ? tussenkalf.kpiValues.map((x) => ({
        sort: `${x.periodYear}${prepPeriodValue(x.periodValue)}`,
        x: `${t(`month.${x.periodValue}`)} ${x.periodYear}`,
        y: x.value,
      }))
    : ocb_tussenkalftd.kpiValues.map((x) => ({
        sort: `${x.periodYear}${prepPeriodValue(x.periodValue)}`,
        x: `${t(`month.${x.periodValue}`)} ${x.periodYear}`,
        y: x.value,
      }));

  let refData =
    benchmark_ocb_tussenkalftd &&
    benchmark_ocb_tussenkalftd.kpiValues.map((x) => ({
      sort: `${x.periodYear}${prepPeriodValue(x.periodValue)}`,
      x: `${t(`month.${x.periodValue}`)} ${x.periodYear}`,
      y: x.value,
    }));

  const nivoData = [];
  if (myData && refData) {
    const uniquePeriods = [...new Set([...myData.map((d) => d.sort), ...refData.map((d) => d.sort)])].sort();
    const uniquePeriodsWithData = uniquePeriods.map((period) => ({
      sort: period,
      data: myData.find((d) => d.sort === period) || refData.find((d) => d.sort === period),
    }));
    myData = uniquePeriodsWithData.map((period) => myData.find((d) => d.sort === period.sort) || (period.data && { x: period.data.x, y: null }));
    refData = uniquePeriodsWithData.map((period) => refData.find((d) => d.sort === period.sort) || (period.data && { x: period.data.x, y: null }));
  } else if (myData) {
    // In this case just reversing the array should be enough
    myData = myData.reverse();
    refData = [];
  } else if (refData) {
    // In this case just reversing the array should be enough
    refData = refData.reverse();
    myData = [];
  } else {
    // If we don't do this, Nivo might give error
    refData = [];
    myData = [];
  }

  return (
    <>
      <CustomButton gradient icon="eye" onClick={() => setVisible(!visible)}>
        Benchmark
      </CustomButton>
      <CustomModal visible={visible} setVisible={setVisible}>
        <Skeleton loading={loading}>
          <KpiGroup>
            <Row gutter={60} style={{ margin: 0 }}>
              <Col span={24}>
                <Accent>Benchmark Tussenkalftijd</Accent>
              </Col>

              <Col span={24} style={{ marginTop: 30 }}>
                <Line
                  axisLegend={{ left: "Dagen" }}
                  data={[
                    {
                      id: "Referentie bedrijf",
                      data: refData,
                    },
                    {
                      id: "Mijn bedrijf",
                      data: myData,
                    },
                  ]}
                />
              </Col>
            </Row>
          </KpiGroup>
        </Skeleton>
      </CustomModal>
    </>
  );
};
