import React from 'react';
import ReactDOM from 'react-dom';
import { ContextStoreProvider } from 'Components';
import { App } from 'App';
import * as serviceWorker from 'serviceWorker';
import './i18n';

const WrappedApp = () => (
  <ContextStoreProvider>
    <App />
  </ContextStoreProvider>
);

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
