import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { CustomButton, Kpi, Bar, ContextStore, KpiGroup, Accent, CustomModal } from "Components";
import { getBenchmarkAverages } from "util/Adapter";
import { useTranslation } from "react-i18next";

export const Benchmark = ({ period }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const {
    state: {
      kpi: { ocb_emissies, benchmark_ocb_emissies },
    },
    dispatch,
  } = useContext(ContextStore);

  useEffect(() => {
    if (period) {
      getBenchmarkAverages({
        schema: "OCBASIS",
        selectionType: "mlkkoe",
        group: "ocb_emissies",
        periodValue: period,
      })
        .then((r) => dispatch({ type: "setKPI", data: r, group: "benchmark_ocb_emissies" }))
        .finally(() => setLoading(false));
    }
  }, [period]);

  const wurKeys = [
    "co2_mestopsl_bed_waarde0",
    "co2_aanvoer_bed_waarde0",
    "co2_voerprod_bed_waarde0",
    "co2_pensferm_bed_waarde0",
    "co2_melkprod_bed_waarde0",
  ];
  const myBenchmarkData = ocb_emissies.kpiValues.find((x) => x.key === "co2_melkprod_bed_waarde0")
    ? ocb_emissies.kpiValues.filter((x) => wurKeys.includes(x.key))
    : ocb_emissies.kpiValues.filter((x) => !wurKeys.includes(x.key));
  const benchmarkData = benchmark_ocb_emissies
    ? benchmark_ocb_emissies.kpiValues.find((x) => x.key === "co2_melkprod_bed_waarde0")
      ? benchmark_ocb_emissies.kpiValues.filter((x) => wurKeys.includes(x.key))
      : benchmark_ocb_emissies.kpiValues.filter((x) => !wurKeys.includes(x.key))
    : undefined;

  return (
    <>
      <CustomButton gradient icon="eye" onClick={() => setVisible(!visible)}>
        Benchmark
      </CustomButton>
      <CustomModal visible={visible} setVisible={setVisible}>
        <Skeleton loading={loading}>
          <KpiGroup>
            <Row gutter={60} style={{ margin: 0 }}>
              <Col span={24}>
                <Accent>Benchmark Emissies</Accent>
              </Col>
              <Col span={12} style={{ paddingBottom: 30, paddingTop: 30 }}>
                Mijn bedrijf
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_melkprod_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_melkprod_bed_waarde0" type="large" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_tot_mlk_sp" type="large" />
                )}
                <Kpi group="ocb_emissies" kpiKey="over_bod_gras1" />
                <Kpi group="ocb_emissies" kpiKey="over_bod_mais1" />
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "over_bod_akker1") && (
                  <Kpi group="ocb_emissies" kpiKey="over_bod_akker1" />
                )}
                <Kpi group="ocb_emissies" kpiKey="dzh_nh3_bedrgve" />
                {/* {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_mestopsl_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_mestopsl_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_mo" />
                )}
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_aanvoer_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_aanvoer_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_cf_aank" />
                )}
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_voerprod_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_voerprod_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_bnr" />
                )}
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_pensferm_bed_waarde0") ? (
                  <Kpi group="ocb_emissies" kpiKey="co2_pensferm_bed_waarde0" />
                ) : (
                  <Kpi group="ocb_emissies" kpiKey="emco2_pi_pv" />
                )} */}
              </Col>
              <Col span={12} style={{ backgroundColor: "#B468CB", paddingBottom: 30, paddingTop: 30 }}>
                <span style={{ color: "#fff" }}>Benchmark</span>
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_melkprod_bed_waarde0") ? (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="co2_melkprod_bed_waarde0" type="large" color="white" />
                ) : (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="emco2_tot_mlk_sp" type="large" color="white" />
                )}
                <Kpi group="benchmark_ocb_emissies" kpiKey="over_bod_gras1" color="white" />
                <Kpi group="benchmark_ocb_emissies" kpiKey="over_bod_mais1" color="white" />
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "over_bod_akker1") && (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="over_bod_akker1" color="white" />
                )}
                <Kpi group="benchmark_ocb_emissies" kpiKey="dzh_nh3_bedrgve" color="white" />
                {/* {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_mestopsl_bed_waarde0") ? (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="co2_mestopsl_bed_waarde0" color="white" />
                ) : (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="emco2_pi_mo" color="white" />
                )}
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_aanvoer_bed_waarde0") ? (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="co2_aanvoer_bed_waarde0" color="white" />
                ) : (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="emco2_pi_cf_aank" color="white" />
                )}
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_voerprod_bed_waarde0") ? (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="co2_voerprod_bed_waarde0" color="white" />
                ) : (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="emco2_pi_bnr" color="white" />
                )}
                {!loading && ocb_emissies && ocb_emissies.kpiValues.find((x) => x.key === "co2_pensferm_bed_waarde0") ? (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="co2_pensferm_bed_waarde0" color="white" />
                ) : (
                  <Kpi group="benchmark_ocb_emissies" kpiKey="emco2_pi_pv" color="white" />
                )} */}
              </Col>
              <Col span={24} style={{ marginTop: 30 }}>
                <p>Uitstoot CO2-equivalenten per ton melk</p>

                <Bar
                  keys={["Voerproductie", "Mestopslagen", "Aanvoerbronnen", "Pensfermentatie"]}
                  data={[
                    {
                      label: "Mijn bedrijf",
                      ...(myBenchmarkData && myBenchmarkData.map((x) => ({ [t(`kpi.${x.key}.label`)]: x.value })).reduce((a, c) => ({ ...a, ...c }))),
                    },
                    {
                      label: "Referentiebedrijven",
                      ...(benchmarkData && benchmarkData.map((x) => ({ [t(`kpi.${x.key}.label`)]: x.value })).reduce((a, c) => ({ ...a, ...c }))),
                    },
                  ]}
                />
              </Col>
              <Col span={24} style={{ backgroundColor: "#EBF0F2", paddingTop: 30, paddingBottom: 30 }}>
                <Accent type="div">Analyse</Accent>
                <p>
                  Het grootste gedeelte van de CO2 eq. uitstoot komt uit methaan (veevoeding en mestopslag), lachgas (teelt en bemesting) en energie-
                  en brandstofverbruik. Kijk in bovenstaande grafiek waar kansen liggen voor jouw bedrijf. Voor de mogelijkheden van optimalisatie
                  neem contact op met jouw OPTIcow adviseur.
                </p>
              </Col>
            </Row>
          </KpiGroup>
        </Skeleton>
      </CustomModal>
    </>
  );
};
