import { useState, useEffect } from "react";

export const deepFind = (obj, path) => {
  let i;
  let len;

  for (i = 0, path = path.split("."), len = path.length; i < len; i++) {
    obj = obj[path[i]];
  }
  return obj;
};

export const nivoScheme = {
  theme: {
    legends: {
      text: {
        fontSize: 14,
        fontFamily: "Rubik",
      },
    },
    axis: {
      textColor: "#89B931",
      ticks: {
        text: {
          fontSize: 11,
          fontFamily: "Rubik",
        },
      },
      legend: {
        text: {
          fontSize: 14,
          fontFamily: "Rubik",
          fontWeight: 500,
        },
      },
    },
  },
  colors: ["#3DBEE4", "#77B34C", "#E5915A", "#246D82", "#B468CB", "#b0f6c4", "#fec2d0"],
};

// Hook
const cachedScripts = [];
export const useScript = src => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        cachedScripts.push(src);

        // Create script
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad);
          script.removeEventListener("error", onScriptError);
        };
      }
    },
    [src] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
};
