import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { notification } from "antd";

export const bizViewAxios = axios.create({
  baseURL: `${window.API_URL}`,
});

export const joindataAxios = axios.create({
  baseURL: `${window.JOINDATA_API_URL}`,
});

export const klwAxios = axios.create({
  baseURL: `${window.KLW_API_URL}`,
});

export const opticowAxios = axios.create({
  baseURL: `${window.OPTICOW_API_URL}`,
});

export const bizViewMock = new MockAdapter(bizViewAxios, { delayResponse: 150 });

/**
 *
 * @param {import('keycloak-js').KeycloakInstance} keycloak
 */
export async function setupAxiosWithKeycloak(keycloak) {
  const interceptor = (config) =>
    new Promise((resolve) =>
      keycloak
        .updateToken(5)
        .success(() => {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
          resolve(config);
        })
        .error(() => {
          keycloak.login();
        })
    );
  bizViewAxios.interceptors.request.use(interceptor);
  joindataAxios.interceptors.request.use(interceptor);
  klwAxios.interceptors.request.use(interceptor);
  opticowAxios.interceptors.request.use(interceptor);
}

const errorInterceptor = (error) => {
  // Do something with response error
  let errorText = "Onbekende fout";
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorText = error.response.data;

    if (error.response.data.message) errorText = error.response.data.message;
  } else if (error.request) {
    errorText = "Er gaat iets fout in de request, zie console voor meer informatie";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorText = error.message;
  }
  notification.error({ message: errorText });
  return Promise.reject(error);
};

bizViewAxios.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
);
joindataAxios.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
);
klwAxios.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
);
opticowAxios.interceptors.response.use(
  (response) => response,
  (error) => errorInterceptor(error)
);

if (!window.MOCK) bizViewMock.restore();
