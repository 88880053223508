import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ContextStore } from "Components/Store";
import { useTranslation } from "react-i18next";
import { Tooltip, Icon } from "antd";
import styles from "./markup.module.scss";

export const KpiGroup = ({ children }) => <div className={styles.kpiGroup}>{children}</div>;

export const Kpi = ({ group, kpiKey, type, color, align, format = "float", minimumFractionDigits = 0 }) => {
  const { state } = useContext(ContextStore);
  const kpi = state.kpi[group] && state.kpi[group].kpiValues.find(x => x.key === kpiKey);
  if (!kpi) return "";

  return <KpiDetail kpi={kpi} type={type} color={color} align={align} format={format} minimumFractionDigits={minimumFractionDigits} />;
};

export const KpiDetail = ({ kpi, type, color, align, format, minimumFractionDigits }) => {
  const { t } = useTranslation();
  const { key, value } = kpi;

  const formattedValue =
    format === "percentage"
      ? parseFloat(value * 100).toLocaleString("nl-NL")
      : format === false
      ? value
      : parseFloat(value).toLocaleString("nl-NL", { minimumFractionDigits });

  if (type === "value") {
    return formattedValue;
  }

  const kpiInfo = t([`kpi.${key}.info`, ""]);

  // STYLES
  const kpiStyles = [styles.kpi, styles[type], styles[align]];
  if (color) kpiStyles.push(styles[color]);

  return (
    <div className={kpiStyles.join(" ")}>
      <div className={styles.valueWrapper}>
        <span className={styles.value}>{formattedValue}</span>
        <span className={styles.unit}>{t([`kpi.${key}.unit`, ""])}</span>
      </div>
      <div className={styles.labelWrapper}>
        <div className={styles.label}>{t([`kpi.${key}.label`, ""])}</div>
        <div className={styles.subLabel}>
          {t([`kpi.${key}.subLabel`, ""])}{" "}
          {kpiInfo && (
            <Tooltip title={kpiInfo}>
              <Icon type="question-circle" style={{ marginLeft: 5 }} />
            </Tooltip>
          )}
        </div>
        <div className={styles.description}>{t([`kpi.${key}.description`, ""])}</div>
      </div>
    </div>
  );
};

Kpi.defaultProps = {
  type: "regular",
  color: "",
  align: "left",
};

Kpi.propTypes = {
  type: PropTypes.oneOf(["large", "regular", "featured", "small", "value"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  color: PropTypes.string,
};
