import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Skeleton, Table } from "antd";
import { CustomButton, Kpi, Bar, ContextStore, KpiGroup, Accent, CustomModal, Line } from "Components";
import { getBenchmarkAverages, getBenchmark, getRollingGroup, getRollingBenchmark, getBizViewGroup, getLatest } from "util/Adapter";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Plot } from "Components/Charts";

export const Benchmark = ({ periodYear, periodMonth }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [axis, setAxis] = useState({ y: "mpr_lft_afv", x: "rv_i105_mnd" });

  const [myData, setMyData] = useState();
  const [benchmarkData, setBenchmarkData] = useState([]);
  const currentDate = moment();

  const { state, dispatch } = useContext(ContextStore);

  useEffect(() => {
    getRollingBenchmark({
      schema: "OCBASIS",
      selectionType: "mlkkoe",
      group: "OCB_LFTD_LEV2TAB",
      periodValue: periodYear === currentDate.year() ? periodYear - 1 : periodYear,
      periodNumber: periodYear === currentDate.year() ? 12 : periodMonth,
      numberOfPeriods: 1,
    })
      .then((r) => dispatch({ type: "setKPI", data: r, group: "benchmark_ocb_lftd_lev2tab" }))
      .finally(() => setLoading(false));

    getRollingGroup({ group: "ocb_lftd_lev2grf", numberOfPeriods: 1 }).then((r) => setMyData(r.data));
    getBenchmark({
      schema: "OCBASIS",
      selectionType: "mlkkoe",
      group: "ocb_lftd_lev2grf",
      periodUnit: "m01",
      periodValue: periodYear === currentDate.year() ? periodYear - 1 : periodYear,
      periodNumber: periodYear === currentDate.year() ? 12 : periodMonth,
      numberOfPeriods: 1,
    }).then((r) => setBenchmarkData(r));
  }, []);

  const tableColumns = [
    {
      dataIndex: "label",
    },
    {
      title: <Accent type="h3">Mijn bedrijf</Accent>,
      align: "right",
      children: [
        {
          title: "Aanwezig",
          dataIndex: "mijn_aanwezig",
          key: "mijn_aanwezig",
          align: "right",
        },
        {
          title: "Afwezig",
          dataIndex: "mijn_afwezig",
          key: "mijn_afwezig",
          align: "right",
        },
      ],
    },
    {
      title: <Accent type="h3">Ref bedrijf</Accent>,
      align: "right",
      children: [
        {
          title: "Aanwezig",
          dataIndex: "ref_aanwezig",
          key: "ref_aanwezig",
          align: "right",
        },
        {
          title: "Afwezig",
          dataIndex: "ref_afwezig",
          key: "ref_afwezig",
          align: "right",
        },
      ],
    },
  ];

  const TableData = [
    {
      label: "Leeftijd (jr.mnd)",
      mijn_aanwezig: <Kpi type="value" format={false} group="ocb_lftd_lev2tab" kpiKey="mpr_lft_aan" />,
      mijn_afwezig: <Kpi type="value" format={false} group="ocb_lftd_lev2tab" kpiKey="mpr_lft_afv" />,
      ref_aanwezig: <Kpi type="value" format={false} group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_lft_aan" />,
      ref_afwezig: <Kpi type="value" format={false} group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_lft_afv" />,
    },
    {
      label: "Kg Melk",
      mijn_aanwezig: <Kpi type="value" group="ocb_lftd_lev2tab" kpiKey="mpr_melk_aan" />,
      mijn_afwezig: <Kpi type="value" group="ocb_lftd_lev2tab" kpiKey="mpr_mlk_afv" />,
      ref_aanwezig: <Kpi type="value" group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_melk_aan" />,
      ref_afwezig: <Kpi type="value" group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_mlk_afv" />,
    },
    {
      label: "Kg Vet",
      mijn_aanwezig: <Kpi type="value" group="ocb_lftd_lev2tab" kpiKey="mpr_kg_vet_aan" />,
      mijn_afwezig: <Kpi type="value" group="ocb_lftd_lev2tab" kpiKey="mpr_kg_vet_afv" />,
      ref_aanwezig: <Kpi type="value" group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_kg_vet_aan" />,
      ref_afwezig: <Kpi type="value" group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_kg_vet_afv" />,
    },
    {
      label: "Kg Eiwit",
      mijn_aanwezig: <Kpi type="value" group="ocb_lftd_lev2tab" kpiKey="mpr_kg_eiw_aan" />,
      mijn_afwezig: <Kpi type="value" group="ocb_lftd_lev2tab" kpiKey="mpr_kg_eiw_afv" />,
      ref_aanwezig: <Kpi type="value" group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_kg_eiw_aan" />,
      ref_afwezig: <Kpi type="value" group="benchmark_ocb_lftd_lev2tab" kpiKey="mpr_kg_eiw_afv" />,
    },
  ];

  const xValue = myData && myData.kpiValues.find((x) => x.key === axis.x);
  const yValue = myData && myData.kpiValues.find((x) => x.key === axis.y);

  const myBenchmarkData = myData && {
    x: xValue ? parseFloat(xValue.value) : 0,
    y: yValue ? parseFloat(yValue.value) : 0,
  };

  return (
    <>
      <CustomButton gradient icon="eye" onClick={() => setVisible(!visible)}>
        Benchmark
      </CustomButton>
      <CustomModal visible={visible} setVisible={setVisible}>
        <Skeleton loading={loading}>
          <KpiGroup>
            <Row gutter={60} style={{ margin: 0 }}>
              <Col span={24}>
                <Accent>Benchmark Opbouw veestapel</Accent>
              </Col>
              <Col span={24}>
                <Table size="middle" bordered={false} rowKey="label" dataSource={TableData} columns={tableColumns} pagination={false} />
              </Col>
              <Col span={24} style={{ marginTop: 30 }}>
                <Plot myData={myBenchmarkData} data={benchmarkData.filter((x) => x.data.length > 0)} x={axis.x} y={axis.y} />
              </Col>
            </Row>
          </KpiGroup>
        </Skeleton>
      </CustomModal>
    </>
  );
};
