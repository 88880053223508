import React from "react";
import styles from "./markup.module.scss";

export const Accent = ({ type, variant, weight, children, style }) => {
  const accentStyles = [styles.accent];
  if (variant) accentStyles.push(styles[variant]);
  if (weight) accentStyles.push(styles[weight]);

  accentStyles.join(" ");
  switch (type) {
    case "h1":
      return (
        <h1 className={accentStyles.join(" ")} style={style}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={accentStyles.join(" ")} style={style}>
          {children}
        </h2>
      );
    case "div":
      return (
        <div className={accentStyles.join(" ")} style={style}>
          {children}
        </div>
      );
    default:
      return (
        <span className={accentStyles.join(" ")} style={style}>
          {children}
        </span>
      );
  }
};
