import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Skeleton, Table } from "antd";
import { CustomButton, Kpi, ContextStore, KpiGroup, Accent, CustomModal } from "Components";
import { getRollingGroup } from "util/Adapter";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const Benchmark = ({ periodYear, periodMonth }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ocbDdd2, setOcbDdd2] = useState();
  const { t } = useTranslation();
  const currentDate = moment();
  useEffect(() => {
    getRollingGroup({
      group: "OCB_DDD2",
      periodUnit: "R",
      numberOfPeriods: 12,
      periodValue: periodYear === currentDate.year() ? periodYear - 1 : periodYear,
      periodNumber: periodYear === currentDate.year() ? 12 : periodMonth,
    })
      .then((r) => setOcbDdd2(r.data))
      .then(() => setLoading(false));
  }, []);

  const periodValues = [3, 6, 9, 12];

  const BenchmarkWithData = () => {
    const keys = Array.from(new Set(ocbDdd2.kpiValues.filter((x) => periodValues.includes(x.periodValue)).map((x) => x.key)));
    const periods = Array.from(
      new Set(ocbDdd2.kpiValues.filter((x) => periodValues.includes(x.periodValue)).map((x) => `period-${x.periodValue}-${x.periodYear}`))
    );
    const tableColumns = [
      {
        dataIndex: "label",
      },
      ...periods.reverse().map((x) => {
        const title = x.split("-");
        const quarter = title[1] / 3;
        return {
          dataIndex: x,
          align: "right",
          title: `${t(`k${quarter}`)} ${title[2]}`,
        };
      }),
    ];

    const tableData = keys.map((k) =>
      ocbDdd2.kpiValues
        .filter((val) => val.key === k)
        .map((val) => {
          const periodKey = `period-${val.periodValue}-${val.periodYear}`;
          const formattedValue = parseFloat(val.value).toLocaleString("nl-NL");

          return {
            label: t(`kpi.${val.key}.label`),
            [periodKey]: formattedValue,
          };
        })
        .reduce((a, b) => ({ ...a, ...b }))
    );

    return (
      <KpiGroup>
        <Row gutter={60} style={{ margin: 0 }}>
          <Col span={24}>
            <Accent>Benchmark DierDagDosering</Accent>
          </Col>
          <Col span={24}>
            <Table size="middle" bordered={false} rowKey="label" dataSource={tableData} columns={tableColumns} pagination={false} />
          </Col>
        </Row>
      </KpiGroup>
    );
  };

  return (
    <>
      <CustomButton gradient icon="eye" onClick={() => setVisible(!visible)}>
        Benchmark
      </CustomButton>
      <CustomModal visible={visible} setVisible={setVisible}>
        <Skeleton loading={loading}>
          <BenchmarkWithData />
        </Skeleton>
      </CustomModal>
    </>
  );
};
