import React, { useEffect, useState, Suspense } from "react";
import { Slacht } from "Components/Cards/Slacht";
import { Skeleton, Row, Col, Spin, Button } from "antd";
import { CustomCard, CustomButton, KpiGroup, JoinDataIntegration } from "Components";
import { getSlachtDataParticipation } from "util/Adapter";
import { ContactInfo } from "Components/ContactInfo";

export const Slachtresultaten = () => {
  const [participation, setParticipation] = useState();
  const [joinDataVisible, setJoinDataVisible] = useState(false);
  const loading = false;

  useEffect(() => {
    getSlachtDataParticipation().then((sdParticipation) => {
      setParticipation(sdParticipation);
      openJoinData(sdParticipation);
    });
  }, []);

  const openJoinData = (sdParticipation) => {
    if (sdParticipation && sdParticipation.participation && sdParticipation.participationCreated) {
      setJoinDataVisible(true);
    }
  };

  const onCloseHandler = () => {
    setJoinDataVisible(false);
    getSlachtDataParticipation().then(setParticipation);
  };

  if (joinDataVisible) {
    return (
      <Suspense fallback={<Spin />}>
        <JoinDataIntegration onClose={onCloseHandler} participationId={participation.participation.id} visible={participation.created} />
      </Suspense>
    );
  }

  return (
    <>
      <Skeleton loading={loading} active>
        <CustomCard bordered={false} bodyStyle={{ padding: 30 }}>
          <KpiGroup>
            <Row type="flex" align="middle" style={{ marginBottom: "12px" }}>
              <Col md={12} span={24}>
                Slachtresultaten
              </Col>
            </Row>
            {participation && !participation.participationCreated && participation.participation === null ? (
              <p>
                In onderstaande tabel vind je een overzicht van de laatste slachtgegevens afkomst van Vion slachterijen. Wil je een compleet overzicht
                over jouw bedrijf van de gegevens afkomstig van Vion slachterijen? Klik dan op de link Vion RundveeNet om deze te bekijken.
              </p>
            ) : (
              <>
                <p>
                  Geen gegevens zichtbaar in onderstaande tabel? Dat kan mogelijk komen dat er nog geen machtiging is afgegeven, klik op de
                  onderstaande knop om alsnog de machtiging af te geven:
                </p>
                <Button type="primary" onClick={() => openJoinData(participation)} style={{ marginBottom: "16px" }}>
                  Machtiging afgeven
                </Button>
                <p>Machtiging wel afgegeven en geen gegevens beschikbaar? Neem dan contact op met:</p>
                <ContactInfo />
              </>
            )}
          </KpiGroup>
        </CustomCard>
      </Skeleton>
      <Row style={{ marginBottom: "24px", textAlign: "right" }} gutter={24}>
        <Col offset={15} span={4}>
          <CustomButton type="default" icon="link" href={window.RUNDVEENET_URL} target="_blank">
            Vion RundveeNet
          </CustomButton>
        </Col>
        <Col span={5}>
          <CustomButton type="default" icon="link" href={window.VION_RUNDVEE_URL} target="_blank">
            Rundveepagina Vion
          </CustomButton>
        </Col>
      </Row>
      <Slacht participation={participation} />
    </>
  );
};
