import React from "react";
import { Modal } from "antd";

export const CustomModal = ({ children, visible, onClose, setVisible, ...rest }) => (
  <Modal
    visible={visible}
    onCancel={() => {
      if (onClose) onClose();
      if (setVisible) setVisible(false);
    }}
    footer={false}
    destroyOnClose
    centered
    bodyStyle={{ padding: "20px 0 0" }}
    width={750}
    style={{ borderRadius: 0 }}
    {...rest}
  >
    {children}
  </Modal>
);
