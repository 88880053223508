import React from "react";
import { CustomCard } from "Components/Grid";
import { KpiGroup } from "Components/Markup";
import { Empty } from "antd";

export const NoData = ({ name }) => {
  return (
    <CustomCard bordered={false} bodyStyle={{ padding: 30 }}>
      <KpiGroup>
        <p>{name}</p>
        <Empty description="Geen data gevonden voor deze periode" />
      </KpiGroup>
    </CustomCard>
  );
};
