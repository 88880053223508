import React, { useContext, useState, useEffect } from "react";
import { Kpi, ContextStore, CustomCard } from "Components";

import { useTranslation } from "react-i18next";
import { getBizViewGroup, getPeriods, getLatest } from "util/Adapter";
import { Skeleton, Row, Col, Select } from "antd";
import { PeriodNote } from "Components/PeriodNote";
import { NoData } from "Components/NoData";
import { RatioDiagram } from "./RatioDiagram";

const { Option } = Select;

export const Voer = ({ period }) => {
  const {
    state: {
      kpi: { ocb_voer },
    },
    dispatch,
  } = useContext(ContextStore);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setLoading(true);
    setHasData(false);
    if (period) {
      // Misschien nog veranderen naar ocb_voer?
      getPeriods("ocb_algemeen", "j").then((ps) => {
        const periodFound = ps.find((p) => p.value === period);
        if (periodFound) {
          getBizViewGroup("ocb_voer", undefined, period)
            .then((res) => {
              dispatch({ type: "setKPI", data: res.data, group: "ocb_voer" });
              setHasData(true);
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("ocb_voer", "j")
        .then((res) => {
          dispatch({ type: "setKPI", data: res.data, group: "ocb_voer" });
          setHasData(true);
        })
        .finally(() => setLoading(false));
    }
  }, [period]);

  const data =
    ocb_voer &&
    ocb_voer.kpiValues
      .filter((v) => v.key !== "voereff_fpcm")
      .map(({ key, value }) => {
        return {
          id: `${value}% ${t(`kpi.${key}.label`)}`,
          label: `${value} ${key}`,
          value: parseFloat(value),
        };
      });

  return (
    <Skeleton loading={loading} active>
      {hasData && data ? (
        <CustomCard>
          <Kpi group="ocb_voer" kpiKey="voereff_fpcm" type="featured" />
          <RatioDiagram data={data} />
          <PeriodNote value={period} group="ocb_voer" />
        </CustomCard>
      ) : (
        <NoData name="Voerefficiëntie" />
      )}
    </Skeleton>
  );
};
