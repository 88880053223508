import React, { useState, useEffect, useContext } from "react";
import { Skeleton, Row, Col } from "antd";
import { CustomCard, KpiGroup, Kpi, PeriodNote, NoData } from "Components";
import { getBizViewGroup, getRollingGroup, getRollingBenchmark, getLatest, getPeriods } from "util/Adapter";
import { ContextStore } from "Components/Store";
import { Bar } from "Components/Charts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Accent } from "Components/Markup";
import { Benchmark } from "./benchmark";

export const Afkalf = ({ periodYear, periodMonth }) => {
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [latestPeriod, setLatestPeriod] = useState();
  const currentDate = moment();

  const { t } = useTranslation();
  const {
    state: {
      kpi: { ocb_lftd_met_ref, benchmark_ocb_lftd_met_ref },
    },
    dispatch,
  } = useContext(ContextStore);

  useEffect(() => {
    setLoading(true);
    setHasData(false);
    if (periodYear && periodMonth) {
      getPeriods("ocb_lftd_met_ref", "M01").then((ps) => {
        const periodFound = ps.find((p) => p.value === periodYear);
        if (periodFound) {
          Promise.all([
            getRollingGroup({ group: "ocb_lftd_met_ref", periodValue: periodYear, periodNumber: periodMonth, periodUnit: "M01" }).then((r) => {
              dispatch({ type: "setKPI", data: r.data, group: "ocb_lftd_met_ref" });
            }),
            getRollingBenchmark({
              group: "ocb_lftd_met_ref",
              periodValue: periodYear,
              periodNumber: periodMonth,
              numberOfPeriods: 1,
              periodUnit: "M01",
            }).then((r) => dispatch({ type: "setKPI", data: r, group: "benchmark_ocb_lftd_met_ref" })),
            getRollingGroup({ group: "ocb_lftd_lev2tab", periodValue: periodYear, periodNumber: periodMonth, periodUnit: "M01" }).then((r) =>
              dispatch({ type: "setKPI", data: r.data, group: "ocb_lftd_lev2tab" })
            ),
          ]).then(() => {
            setLoading(false);
            setHasData(true);
          });
        } else {
          setLoading(false);
        }
      });
    } else {
      getLatest("ocb_lftd_lev2tab", "M01").then((r) => {
        dispatch({ type: "setKPI", data: r.data, group: "ocb_lftd_lev2tab" });
        setLatestPeriod(r.data.period);
      });
      getLatest("ocb_lftd_met_ref", "M01")
        .then((r) => {
          dispatch({ type: "setKPI", data: r.data, group: "ocb_lftd_met_ref" });
          return getRollingBenchmark({
            group: "ocb_lftd_met_ref",
            periodValue: r.data.period.value === currentDate.year() ? r.data.period.value - 1 : r.data.period.value,
            periodNumber: r.data.period.value === currentDate.year() ? 12 : r.data.period.number,
            numberOfPeriods: 1,
          }).then((res) => dispatch({ type: "setKPI", data: res, group: "benchmark_ocb_lftd_met_ref" }));
        })
        .then(() => {
          setLoading(false);
          setHasData(true);
        });
    }
  }, [periodYear, periodMonth]);

  return (
    <Skeleton loading={loading} active>
      {hasData ? (
        <CustomCard bordered={false} bodyStyle={{ padiding: 30 }} className={["split"]}>
          <KpiGroup>
            <Row type="flex" align="middle" style={{ marginBottom: 10 }}>
              <Col span={12}>Opbouw veestapel</Col>
              <Col span={12} align="right">
                <Benchmark periodYear={periodYear || latestPeriod.value} periodMonth={periodMonth || latestPeriod.number} />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24} md={8}>
                <Accent>Mijn bedrijf</Accent>
                <Kpi group="ocb_lftd_met_ref" kpiKey="mpr_lft_afv" format={false} />
                <Kpi group="ocb_lftd_met_ref" kpiKey="mpr_lft_aan" format={false} />
                <Kpi group="ocb_lftd_met_ref" kpiKey="rv_i105_mnd" />
              </Col>
              <Col span={24} md={8}>
                <Bar
                  axisLeft={null}
                  legends={[
                    {
                      dataFrom: "keys",
                      reverse: true,
                      anchor: "bottom",
                      direction: "column",
                      justify: false,
                      translateY: 80,
                      itemsSpacing: 2,
                      itemWidth: 180,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      symbolSize: 10,
                      symbolShape: "square",
                    },
                  ]}
                  keys={[t("kpi.rv_i105_mnd.label"), t("kpi.mpr_lft_aan_mnd.label")]}
                  data={[
                    {
                      label: "Mijn bedrijf",
                      ...(ocb_lftd_met_ref &&
                        ocb_lftd_met_ref.kpiValues.length > 0 &&
                        ocb_lftd_met_ref.kpiValues.map((x) => ({ [t(`kpi.${x.key}.label`)]: x.value })).reduce((a, c) => ({ ...a, ...c }))),
                    },
                    {
                      label: "Referentie bedrijf",
                      ...(benchmark_ocb_lftd_met_ref &&
                        benchmark_ocb_lftd_met_ref.kpiValues.length > 0 &&
                        benchmark_ocb_lftd_met_ref.kpiValues.map((x) => ({ [t(`kpi.${x.key}.label`)]: x.value })).reduce((a, c) => ({ ...a, ...c }))),
                    },
                  ]}
                />
              </Col>
              <Col span={24} md={8}>
                <Accent>Referentie bedrijf</Accent>
                <Kpi group="benchmark_ocb_lftd_met_ref" kpiKey="mpr_lft_afv" format={false} />
                <Kpi group="benchmark_ocb_lftd_met_ref" kpiKey="mpr_lft_aan" format={false} />
                <Kpi group="benchmark_ocb_lftd_met_ref" kpiKey="rv_i105_mnd" />
              </Col>
            </Row>
          </KpiGroup>
          <PeriodNote value={periodYear && periodMonth ? `${periodYear}-${periodMonth}` : undefined} group="ocb_lftd_met_ref" />
        </CustomCard>
      ) : (
        <NoData name="Opbouw veestapel" />
      )}
    </Skeleton>
  );
};
