import React, { Suspense, useEffect, useState, useContext } from "react";
import { Route, Switch, useHistory, Link } from "react-router-dom";

import { Container, Navigation, General, Footer, CustomButton, ContextStore, Accent } from "Components";
import { Spin, Result, Row, Col, Badge } from "antd";
import { getKlwData, startCalculation, getRelation, getSlachtDataAmountSinceLastLogin } from "util/Adapter";
import { useTranslation } from "react-i18next";
import Milkingcow from "Assets/milking-cow.gif";
import { Kringloop, Veemanagement, Financieel, Slachtresultaten } from "Pages";

export const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(ContextStore);
  useEffect(() => {
    getRelation().then((r) => dispatch({ group: "relation", data: r }));

    if (process.env.NODE_ENV !== "development") {
      getKlwData("KLW_KGLN_ZLTO", 2019).finally(() => {
        setLoading(false);
      });
    } else setLoading(false);
  }, []);

  return (
    <Container>
      <Navigation />
      {loading && <LoadingKLW />}
      {!loading && <DashboardComponents />}
    </Container>
  );
};

const LoadingKLW = () => {
  const { t } = useTranslation();
  return (
    <Result
      icon={
        <img
          src={Milkingcow}
          style={{
            objectFit: "cover",
            width: 400,
            height: 400,
            borderRadius: 400,
            border: "1px solid white",
            boxShadow: "0 0 20px rgba(0,0,0,.1)",
          }}
          alt="Loading.."
        />
      }
      subTitle={t("loading.klw")}
    />
  );
};

const DashboardComponents = () => {
  const history = useHistory();
  const [amountSinceLastLogin, setAmountSinceLastLogin] = useState(0);
  const { location } = history;
  const {
    state: { pageActions },
  } = useContext(ContextStore);

  useEffect(() => {
    getSlachtDataAmountSinceLastLogin().then((result) => {
      setAmountSinceLastLogin(result.amount);
    });
  }, []);

  const isActive = (path) => location.pathname === path;

  return (
    <Suspense fallback={<Spin />}>
      <General />
      <Row gutter={20} type="flex" style={{ margin: "2rem 0" }}>
        <Col span={24}>
          <Accent type="h2" style={{ paddingLeft: "1rem" }}>
            Kies een categorie
          </Accent>
        </Col>
        <Col>
          <Link to="/">
            <CustomButton type={isActive("/") ? "primary" : "link"} gradient={isActive("/")}>
              Kringloop
            </CustomButton>
          </Link>
        </Col>
        <Col>
          <Link to="/veemanagement">
            <CustomButton type={isActive("/veemanagement") ? "primary" : "link"} gradient={isActive("/veemanagement")}>
              Veemanagement
            </CustomButton>
          </Link>
        </Col>
        <Col>
          <Link to="/financieel">
            <CustomButton type={isActive("/financieel") ? "primary" : "link"} gradient={isActive("/financieel")}>
              Financieel
            </CustomButton>
          </Link>
        </Col>
        <Col>
          <Link to="/slachtresultaten">
            <Badge count={amountSinceLastLogin} showZero={false}>
              <CustomButton type={isActive("/slachtresultaten") ? "primary" : "link"} gradient={isActive("/slachtresultaten")}>
                Slachtresultaten
              </CustomButton>
            </Badge>
          </Link>
        </Col>
        <Col>{pageActions}</Col>
      </Row>
      <Switch>
        <Route exact path="/" component={Kringloop} />
        <Route exact path="/veemanagement" component={Veemanagement} />
        <Route exact path="/financieel" component={Financieel} />
        <Route exact path="/slachtresultaten" component={Slachtresultaten} />
      </Switch>
      <Footer />
    </Suspense>
  );
};
